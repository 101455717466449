<template>
  <div>
    <b-modal
      id="modal-input-role"
      size="lg"
      centered
      title="Tambah Data Master Role"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <b-form @keydown.enter.prevent="">
        
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Role <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('nama_role')"
            type="text"
            v-model="$v.is_data.nama_role.$model"
          ></b-form-input>
        </b-form-group>
        
      </b-form>
      <!-- <img :src="src1" /> -->
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-input-role')"
        >
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "modalInput",
  props: ["count"],
  data() {
    return {
      is_data: {
        nama_role: null,
      },
      list_tipe: ["menu", "submenu"],
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
    };
  },
  watch: {
    count: function (newV) {
      this.is_data.sequence = newV;
    },
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pid() {
      return this.$store.state.puskesmas_id
        ? this.$store.state.puskesmas_id
        : null;
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      nama_role: { required },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      if (vm.pid) {
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        vm.$axios
          .post("/ms_role/register", {
            ...vm.is_data,
          })
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$v.$reset();
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN MASTER ROLE",
                showing: true,
              });
              this.$bvModal.hide("modal-input-role");
              // this.resetModal();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: res.data.message.toUpperCase(),
                showing: true,
              });
            }
          })
          .catch(() => {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      } else {
        vm.$emit("alertFromChild", {
          variant: "danger",
          msg: "PUSKESMAS ID TIDAK TERDETEKSI",
          showing: true,
        });
      }
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        nama_role: null,
      };
    },
  },
};
</script>
