<template>
  <div>
    <b-modal
      id="modal-edit-role"
      size="lg"
      centered
      title="Update Data Master Role"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="tutupModal"
    >
      <b-form @keydown.enter.prevent="">
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Role <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('nama_role')"
            type="text"
            v-model="$v.is_data.nama_role.$model"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-edit-role')"
        >
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "modalEdit",
  props: ["data_edit"],
  data() {
    return {
      busy: false,
      button: "Simpan",
      is_data: null,
      list_tipe: ["menu", "submenu"],
    };
  },
  computed: {
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    formString() {
      return JSON.stringify(this.data_edit, 4, null);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
  },
  watch: {
    data_edit(newVal) {
      const vm = this;
      vm.is_data = newVal;
      // console.log(vm.is_data)
    },
  },

  mixins: [validationMixin],
  validations: {
    is_data: {
      nama_role: { required },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post("/ms_role/update", {
          ...vm.is_data,
          id: vm.is_data.ms_role_id,
        })
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH MASTER ROLE",
              showing: true,
            });
            vm.$bvModal.hide("modal-edit-role");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: res.data.message.toUpperCase(),
              showing: true,
            });
          }
        })
        .catch(() => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    tutupModal() {
      this.$emit("tutupModal");
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        nama_alergi: "",
      };
    },
  },
};
</script>
